// @todo this need to be changed with a generic one, not gatsby specific
import { navigate } from "gatsby"
import { template } from "lodash"
import { isBrowser } from "../../../services"

const doRedirect = (url, usePath = false) => {
    if (typeof window !== `undefined`) {
        // looking for external link
        if (url.match(/https|http/)) window.location.href = url
        else if (usePath) window.location.pathname = url
        else navigate(url)
    }
}

// reading params from location search
const useQuery = (location, key) => {
    const query = new URLSearchParams(location.search)
    return query.get(key)
}

// reading params from url
const goBack = () => {
    navigate(-1)
}

const formatString = (string, values) => {
    let compiled = template(string)
    string = compiled(values)

    return string
}

const removeLastSegment = (url) => {
    const segments = url.split('/').filter(Boolean);
    segments.pop();
    return '/' + segments.join('/');
  };

const stripTrailingSlash = (str) => {
    return str.endsWith('/') ?
        str.slice(0, -1) :
        str;
};


const handleTabNavigation = (tabValue, urlParams, returnUrl=false) => {
    console.log('urlParams =>', urlParams)
    if (isBrowser()) {
        let url = `${stripTrailingSlash(window.location.pathname)}/${tabValue}`;
    console.log('urlParams => d', url)

        // if (urlParams?.propertyid && !urlParams?.tab) {
        //     url = `${stripTrailingSlash(window.location.pathname)}/${tabValue}`;
        // }
        if (urlParams?.tab) {
            url = `${removeLastSegment(stripTrailingSlash(window.location.pathname))}/${tabValue}`;
            if (returnUrl)
                return url
            doRedirect(url)
        }
        if (returnUrl)
            return url
        // window.history.pushState({tab:tabValue}, '', url)
        doRedirect(url)
    }
}

export { doRedirect, useQuery, goBack, formatString, removeLastSegment, stripTrailingSlash, handleTabNavigation }
