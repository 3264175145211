
import { createSlice } from "@reduxjs/toolkit"
// import userobjectApi from "../services/userobject"

const initialState = {
};

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    propertyDetail: (state, action) => {
      console.log('qqqqqqq', action)
      const { payload } = action
      return {
        ...state,
        propertyDetail:payload?.property_detail
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { propertyDetail } = propertySlice.actions

export default propertySlice.reducer


