const { CALENDAR, DOCUMENTS, TRANSACTIONS } = require("./index");
const { INBOX } = require("./index");
const { PROFILE } = require("./index");
const { MY_PROPERTY } = require("./index");

const ENTERPRISE_PRESET = [
  MY_PROPERTY,
  PROFILE,
  INBOX,
  DOCUMENTS,
  TRANSACTIONS,
];

export default ENTERPRISE_PRESET;
