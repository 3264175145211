import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
// import Reducers from '../reducers';
import ProfileReducer from '../reducers/profileSlice';
import PropertyPreferenceReducer from '../reducers/propPreferenceSlice';
import PropertyReducer from '../reducers/propertySlice';
import userobjectReducer from '../reducers/userobjectSlice';
import { profileApi } from '../services/profile';
import { userobjectApi } from '../services/userobject';
import { propertyApi } from '../services/property';

const store = configureStore({
    reducer: {
        profile: ProfileReducer,
        propertyPreference: PropertyPreferenceReducer,
        userobjects: userobjectReducer,
        property: PropertyReducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [userobjectApi.reducerPath]: userobjectApi.reducer,
        [propertyApi.reducerPath]: propertyApi.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
        .concat(profileApi.middleware)
        .concat(userobjectApi.middleware)
        .concat(propertyApi.middleware),
});

setupListeners(store.dispatch)

export default store;